import React, { FC, HTMLAttributes } from 'react';
import colors from 'tailwindcss/colors';
import themeConfig from '../../../config/theme.config';
import Logo from '../../../assets/logo512.svg';

type ILogoTemplateProps = HTMLAttributes<HTMLOrSVGElement>;
const LogoTemplate: FC<ILogoTemplateProps> = (props) => {
	const { ...rest } = props;
	return (
		<img src='../../../assets/logo512.svg' alt=''/>
		
	);
};

export default LogoTemplate;
